import React from 'react';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';


class About extends React.Component{   

    options = {
        loop: true,
        center: false,
        items: 2,
        margin: 0,
        autoplay: true,
        dots: false,
        autoplayTimeout: 8500,
        smartSpeed: 450,
        nav: false,
        margin: 40,
    };
    
    render(){
        return (
        <div>
                <div className="nextBG">
                    <iframe 
                    src="https://spectacular-cupcake-4659e6.netlify.app/index6"
                    allowfullscreen></iframe>
                </div>

            <section className="about-us-countdown-area section-padding-100-0 about-section nextExplainArea" id="about">

                <div className="container">
                    <div className="row align-items-center">

                        <div className="col-12">
                        <div className="about-content-text mb-80 \">
                            {/* <h6 className="wow fadeInUp" data-wow-delay="300ms">Tedxiimbangalore</h6> */}
                            <h3 className="wow fadeInUp " data-wow-delay="300ms">Roots<span className='theRed'> & </span>Wings</h3>
                            <p className="wow fadeInUp" data-wow-delay="300ms">
                                The theme "Roots & Wings" embodies the delicate dance between heritage and aspiration. It speaks to the duality of our human experience—grounded in our past, yet driven by an innate desire to soar. Roots represent the foundation upon which we stand: our values, traditions, and past experiences offering strength and clarity. Wings symbolize our yearning for growth, innovation, and the courage to pursue uncharted paths fueling growth and transformation. 
                            </p>
                            <p className="wow fadeInUp" data-wow-delay="300ms">
                            At TEDxIIMBangalore, we explore this dynamic interplay. Through diverse perspectives and compelling stories, we will explore how some draw strength from their past while others find wings through bold ideas and personal evolution. Whether grounded in deep roots or driven by the desire to soar, our speakers will reflect on how the balance between anchoring and flight empowers us to redefine our paths.
                            </p>
                            <p className="wow fadeInUp" data-wow-delay="300ms">
                            This theme invites us to contemplate the delicate balance between honoring our past and daring to transcend it. It’s a journey of self-discovery, where we navigate the tension between stability and evolution. "Roots & Wings" reminds us that true growth lies not in where we begin, but in the heights we dare to reach.
                            </p>
                        
                        </div>
                        </div>


                    </div>
                </div>

               
            </section>
            





            







            
        </div>
        )
    };
}
export default About;
